import React, {useContext} from "react"
import styled, {ThemeContext} from "styled-components"
import {Flex} from "./UI"

const StarBox = styled(Flex)`
    align-self: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 0;
    
    svg {
        transform: scale(0.5);
        transform-origin: left center; 
        margin-right: -3px;
    }
`

const Circle = ({color, fill}) =>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill={fill ? color : 'none'} fillRule="evenodd">
            <g id="Icon/Stars/01" transform="translate(-28.000000, -5.000000)" stroke={color} strokeWidth="3">
                <circle id="Oval-Copy" cx="36" cy="13" r="6.5"></circle>
            </g>
        </g>
    </svg>

const Stars = ({stars, inverse}) => {
    const themeContext = useContext(ThemeContext)

    const color = inverse ? themeContext.colors.black : themeContext.colors.red

    return (
        <StarBox>
            <Circle fill={stars > 0} color={stars > 0 ? color : themeContext.colors.dGrey}/>
            <Circle fill={stars > 1} color={stars > 1 ? color : themeContext.colors.dGrey}/>
            <Circle fill={stars > 2} color={stars > 2 ? color : themeContext.colors.dGrey}/>
            <Circle fill={stars > 3} color={stars > 3 ? color : themeContext.colors.dGrey}/>
            <Circle fill={stars > 4} color={stars > 4 ? color : themeContext.colors.dGrey}/>
        </StarBox>
    )
}

export default Stars;
