import _ from 'lodash'
import React, {useState} from 'react'
import styled from 'styled-components'
import {Flex, Span} from './UI'
import { theme } from '../styles/defaultTheme'


const PriceLabel = styled(Span)`
    ${props => props.theme.fontStyle.h1};
    font-size: 2.375rem !important;
    
    sup {
        ${props => props.theme.fontStyle.h3};
        margin-left: -6px;
    }
`

const StyledSpan = styled(Span)`
    font-size: 0.75rem;
    line-height: 1.5;
`

const PriceBox = styled(Flex)`
    flex-direction: row; 
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    align-items: center;
`

const MemberPrice = styled(StyledSpan)`
    display: inline-block;
    color: ${props => props.theme.colors.white};
    font-size: 0.75rem;
    
    span {
        color: ${props => props.inverse ? props.theme.colors.black : props.theme.colors.white};
    }
`

const Price = ({inverse, ...props}) => {
    const [price, setPrice] = useState(0)

    useState(() => {
        setPrice(_.get(props, 'price'))
    }, [])

    return (
        <>
            <PriceBox>
                <PriceLabel>{ price }</PriceLabel>
            </PriceBox>
            {
                _.get(props, 'stars') > 1
                    ? <MemberPrice inverse={inverse}>UP Club-leden: <span style={{color: inverse ? '#000' : '#FF3A00'}}>{`€${_.get(props, 'price_loyalty')}`}</span></MemberPrice>
                    : <MemberPrice inverse={inverse}><span> </span></MemberPrice>
            }
        </>
    )
}

export default Price
