import styled from "styled-components";
import React from "react";

const Container = styled.div`
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 80px;
    height: 80px;
    background-color: ${props => props.theme.colors.red};
    border-radius: 50%;
    border: ${props => props.inverse ? `3px solid black` : 'none'};
    
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${props => props.theme.colors.white};
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        line-height: 0.7;
        
        small {
            font-size: 0.75rem;
            font-weight: normal;
        }
    }
`

const Stamp = ({ count, inverse }) =>
    <Container inverse={inverse}>
        <span>+{count}<br/><small>{`stamp${count > 1 ? 's' : ''}`}</small></span>
    </Container>

export default Stamp
